import React, { useState, useEffect } from "react";
import { Segment, Form, Grid, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useAuth0 } from "../../../../auth/auth0";

import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import { DateTimePicker } from "react-widgets";
import TrialSearchResults from "./TrialSearchResults";

import * as trialActions from "../../../../redux/actions/trialActions";

const TrialSearch = ({
	growingSeasonOptions,
	selectedGrowingSeasonId,
	cropOptions,
	selectedCropId,
	stateOptions,
	trialOptions,
	companyOptions,
	cooperatorOptions,
	farmOptions,
	trialOwnerOptions,
	fieldOptions,
	numberOfTreatmentOptions,
	associatedTrials,
	searchedTrials,
	selectedFilterOptions,
	selectTrial,
	updateTrialSearchResults,
	updateSelectedFilterOptions
}) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();

	//-- Data Sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const DATE_FORMAT = "MM/DD/YYYY";

	//Dropdown Options
	const presetTimeOptions = [
		{
			key: "thisYear",
			value: "thisYear",
			text: "This Year"
		},
		{
			key: "thisMonth",
			value: "thisMonth",
			text: "This Month"
		},
		{
			key: "thisWeek",
			value: "thisWeek",
			text: "This Week"
		},
		{
			key: "lastYear",
			value: "lastYear",
			text: "Last Year"
		},
		{
			key: "lastMonth",
			value: "lastMonth",
			text: "Last Month"
		},
		{
			key: "lastWeek",
			value: "lastWeek",
			text: "Last Week"
		}
	];

	//-- Data Sources
	const [trialSearchResults, setTrialSearchResults] = useState([]);
	const [filteredTrialList, setFilteredTrialList] = useState([]);

	//-- Selected filter options
	const [plantDateFrom, setPlantDateFrom] = useState(
		selectedFilterOptions?.plantDateFrom ?? new Date(new Date().getFullYear(), 0, 1)
	);
	const [prevPlantDateFrom, setPrevPlantDateFrom] = useState(
		selectedFilterOptions?.plantDateFrom ?? new Date(new Date().getFullYear(), 0, 1)
	);
	const [plantDateTo, setPlantDateTo] = useState(
		selectedFilterOptions?.plantDateTo ?? new Date(new Date().getFullYear(), 11, 31)
	);
	const [prevPlantDateTo, setPrevPlantDateTo] = useState(
		selectedFilterOptions?.plantDateTo ?? new Date(new Date().getFullYear(), 11, 31)
	);
	const [presetTimeId, setPresetTimeId] = useState(selectedFilterOptions?.presetTimeId ?? "lastYear");

	const [growingSeasonId, setGrowingSeasonId] = useState(
		selectedFilterOptions?.growingSeasonId ?? selectedGrowingSeasonId ?? "all"
	);
	const [cropId, setCropId] = useState(selectedFilterOptions?.cropId ?? selectedCropId ?? "all");
	const [stateId, setStateId] = useState(selectedFilterOptions?.stateId ?? "all");
	const [trialId, setTrialId] = useState(selectedFilterOptions?.trialId ?? "all");
	const [companyId, setCompanyId] = useState(selectedFilterOptions?.companyId ?? "all");
	const [cooperatorId, setCooperatorId] = useState(selectedFilterOptions?.cooperatorId ?? "all");
	const [farmId, setFarmId] = useState(selectedFilterOptions?.farmId ?? "all");
	const [trialOwnerId, setTrialOwnerId] = useState(selectedFilterOptions?.trialOwnerId ?? "all");
	const [fieldId, setFieldId] = useState(selectedFilterOptions?.fieldId ?? "all");
	const [numberOfTreatments, setNumberOfTreatments] = useState(
		selectedFilterOptions?.numberOfTreatments ?? _.maxBy(numberOfTreatmentOptions, "value").value
	);

	//-- UI Control
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (searchedTrials?.length > 0 && selectedFilterOptions?.presetTimeId) {
			setTrialSearchResults(searchedTrials);
		} else if (clientId) {
			searchForTrials();
		}
	}, [clientId]);

	useEffect(() => {
		if (trialSearchResults) {
			handleFilter();
		}
	}, [trialSearchResults]);

	useEffect(() => {
		if (presetTimeId) {
			setPrevPlantDateFrom(plantDateFrom);
			setPrevPlantDateTo(plantDateTo);

			if (presetTimeId === "thisYear") {
				setPlantDateFrom(new Date(new Date().getFullYear(), 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear(), 11, 31));
			} else if (presetTimeId === "thisMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

				setPlantDateFrom(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
				setPlantDateTo(new Date(new Date().getFullYear(), new Date().getMonth(), lastDayOfMonth));
			} else if (presetTimeId === "thisWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 1));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff + 5));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			} else if (presetTimeId === "lastYear") {
				setPlantDateFrom(new Date(new Date().getFullYear() - 1, 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear() - 1, 11, 31));
			} else if (presetTimeId === "lastMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
				let year = new Date().getFullYear();
				if (new Date().getMonth() === 1) {
					year = new Date().getFullYear() - 1;
				}

				setPlantDateFrom(new Date(year, new Date().getMonth() - 1, 1));
				setPlantDateTo(new Date(year, new Date().getMonth() - 1, lastDayOfMonth));
			} else if (presetTimeId === "lastWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 8));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff - 2));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			}
		}
	}, [presetTimeId]);

	async function searchForTrials() {
		if (clientId && plantDateTo && plantDateFrom) {
			const accessToken = await getTokenSilently();
			setLoading(true);
			dispatch(
				trialActions.searchForTrials(
					{
						plantDateFrom: plantDateFrom.toDateString(DATE_FORMAT),
						plantDateTo: plantDateTo.toDateString(DATE_FORMAT)
					},
					clientId,
					false,
					accessToken
				)
			)
				.then((res) => {
					setTrialSearchResults(res.trialInfos);
					updateTrialSearchResults(res.trialInfos);
					setLoading(false);
				})
				.catch((err) => {
					toast.error("Error searching for trials. Please try again.");
					setLoading(false);
					console.log(err);
				});
		}
	}

	function handleFilter() {
		if (trialSearchResults) {
			if (clientId) {
				var mPlantDateFrom = moment(plantDateFrom);
				var mPrevPlantDateFrom = moment(prevPlantDateFrom);
				var mPlantDateTo = moment(plantDateTo);
				var mPrevPlantDateTo = moment(prevPlantDateTo);

				if (!mPlantDateFrom.isSame(mPrevPlantDateFrom) || !mPlantDateTo.isSame(mPrevPlantDateTo)) {
					searchForTrials();
					setPrevPlantDateFrom(plantDateFrom);
					setPrevPlantDateTo(plantDateTo);
				}
			}
		}

		const filteredList = trialSearchResults.filter((tsr) => {
			return (
				//-- growing season
				(growingSeasonId === "all" || tsr.growingSeasonId === growingSeasonId) &&
				//-- crop
				(cropId === "all" || tsr.cropId === cropId) &&
				//-- state
				(stateId === "all" || tsr.stateId === stateId) &&
				//-- trial
				(trialId === "all" || tsr.trialId === trialId) &&
				//-- company
				(companyId === "all" || tsr.companyId === companyId) &&
				//-- cooperator
				(cooperatorId === "all" || tsr.cooperatorCompanyId === cooperatorId) &&
				//-- farm
				(farmId === "all" || tsr.farmId === farmId) &&
				//-- trial owner
				(trialOwnerId === "all" || tsr.trialOwnerId === trialOwnerId) &&
				//-- field
				(fieldId === "all" || tsr.fieldId === fieldId) &&
				//-- number of treatments
				Number(numberOfTreatments) >= Number(tsr.numberOfTreatments)
			);
		});
		setFilteredTrialList(filteredList);

		createFilterObject();
	}

	function resetFilter() {
		setPlantDateFrom(new Date(new Date().getFullYear() - 1, 0, 1));
		setPlantDateTo(new Date(new Date().getFullYear() - 1, 11, 31));
		setPresetTimeId("lastYear");
		setGrowingSeasonId(selectedGrowingSeasonId ?? "all");
		setCropId(selectedCropId ?? "all");
		setStateId("all");
		setTrialId("all");
		setCooperatorId("all");
		setCompanyId("all");
		setFarmId("all");
		setTrialOwnerId("all");
		setFieldId("all");
		let maxTreatment = _.maxBy(numberOfTreatmentOptions, "value");
		setNumberOfTreatments(maxTreatment.value);

		createFilterObject();
	}

	function createFilterObject() {
		let filterOptions = {};
		filterOptions["plantDateFrom"] = plantDateFrom;
		filterOptions["plantDateTo"] = plantDateTo;
		filterOptions["presetTimeId"] = presetTimeId;
		filterOptions["growingSeasonId"] = growingSeasonId;
		filterOptions["cropId"] = cropId;
		filterOptions["stateId"] = stateId;
		filterOptions["trialId"] = trialId;
		filterOptions["companyId"] = companyId;
		filterOptions["cooperatorId"] = cooperatorId;
		filterOptions["farmId"] = farmId;
		filterOptions["trialOwnerId"] = trialOwnerId;
		filterOptions["fieldId"] = fieldId;
		filterOptions["numberOfTreatments"] = numberOfTreatments;

		updateSelectedFilterOptions(filterOptions);
	}

	return (
		<Segment basic>
			<Form onSubmit={handleFilter}>
				<Grid id="trial-search-grid" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column width="3" textAlign="right">
							<Form.Field>
								<label htmlFor="form-dateinput-plant-date-from">Plant Date From*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Field
								id="form-date-time-plant-date-from"
								control={DateTimePicker}
								includeTime={false}
								value={plantDateFrom}
								required
								error={!plantDateFrom}
								onChange={(date) => {
									if (date) {
										date.setHours(0, 0, 0, 0);
										setPlantDateFrom(date);
									} else {
										setPlantDateFrom(null);
									}
								}}
							/>
						</Grid.Column>
						<Grid.Column textAlign="center" style={{ padding: "unset", width: 10 }}>
							<Form.Field>
								<label htmlFor="form-dateinput-plant-date-to">to*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Field
								id="form-date-time-plant-date-to"
								control={DateTimePicker}
								includeTime={false}
								value={plantDateTo}
								required
								error={!plantDateTo}
								onChange={(date) => {
									if (date) {
										date.setHours(0, 0, 0, 0);
										setPlantDateTo(date);
									} else {
										setPlantDateTo(null);
									}
								}}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right" style={{ padding: "unset" }}>
							<Form.Field>
								<label htmlFor="form-select-preset-times">Preset Times</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Select
								id="form-select-preset-times"
								options={presetTimeOptions}
								value={presetTimeId}
								onChange={(event, { value }) => {
									setPresetTimeId(value);
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-growing-season">Growing Season</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-growing-season"
								options={growingSeasonOptions}
								search
								value={growingSeasonId}
								onChange={(event, { value }) => {
									setGrowingSeasonId(value);
								}}
								loading={!growingSeasonOptions || growingSeasonOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-crop">Crop</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-crop"
								options={cropOptions}
								search
								value={cropId}
								onChange={(event, { value }) => {
									setCropId(value);
								}}
								loading={!cropOptions || cropOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-state">State/Territory</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-state"
								options={stateOptions}
								search
								value={stateId}
								onChange={(event, { value }) => {
									setStateId(value);
								}}
								loading={!stateOptions || stateOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-trial">Trial</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-trial"
								options={trialOptions}
								search
								value={trialId}
								onChange={(event, { value }) => {
									setTrialId(value);
								}}
								loading={!trialOptions || trialOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-cooperator">Cooperator</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-cooperator"
								options={cooperatorOptions}
								search
								value={cooperatorId}
								onChange={(event, { value }) => {
									setCooperatorId(value);
								}}
								loading={!cooperatorOptions || cooperatorOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-company">Company</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-company"
								options={companyOptions}
								search
								value={companyId}
								onChange={(event, { value }) => {
									setCompanyId(value);
								}}
								loading={!companyOptions || companyOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-farm">Farm</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-farm"
								options={farmOptions}
								search
								value={farmId}
								onChange={(event, { value }) => {
									setFarmId(value);
								}}
								loading={!farmOptions || farmOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-trial-owners">Trial Owners</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-trial-owners"
								options={trialOwnerOptions}
								search
								value={trialOwnerId}
								onChange={(event, { value }) => {
									setTrialOwnerId(value);
								}}
								loading={!trialOwnerOptions || trialOwnerOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-field">Field</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-field"
								options={fieldOptions}
								search
								value={fieldId}
								onChange={(event, { value }) => {
									setFieldId(value);
								}}
								loading={!fieldOptions || fieldOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-number-of-treatments">Number of Treatments</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-number-of-treatments"
								options={numberOfTreatmentOptions}
								search
								value={numberOfTreatments}
								onChange={(event, { value }) => {
									setNumberOfTreatments(value);
								}}
								loading={!numberOfTreatmentOptions || numberOfTreatmentOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Button floated="right" primary loading={loading}>
								Filter
							</Button>
							<Button floated="right" onClick={resetFilter}>
								Reset
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
			{loading ? null : (
				<TrialSearchResults
					trialList={filteredTrialList}
					associatedTrialList={associatedTrials}
					selectTrial={selectTrial}
				/>
			)}
		</Segment>
	);
};

TrialSearch.propTypes = {
	growingSeasonOptions: PropTypes.array,
	selectedGrowingSeasonId: PropTypes.string,
	cropOptions: PropTypes.array,
	selectedCropId: PropTypes.string,
	stateOptions: PropTypes.array,
	trialOptions: PropTypes.array,
	companyOptions: PropTypes.array,
	cooperatorOptions: PropTypes.array,
	farmOptions: PropTypes.array,
	trialOwnerOptions: PropTypes.array,
	fieldOptions: PropTypes.array,
	numberOfTreatmentOptions: PropTypes.array,
	associatedTrials: PropTypes.array,
	searchedTrials: PropTypes.array,
	selectedFilterOptions: PropTypes.object,
	selectTrial: PropTypes.func,
	updateTrialSearchResults: PropTypes.func,
	updateSelectedFilterOptions: PropTypes.func
};

export default TrialSearch;
